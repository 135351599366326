import React, { useState, useEffect, useLayoutEffect, MutableRefObject } from 'react';
import { useSelector } from 'react-redux';
import BlockLink from 'components/Links/BlockLink/BlockLink';

import { IApplicationState } from 'store/index';
import * as fromViewport from 'store/Viewport';

import { LazyImage } from "components/Image/Index";

import classNames from 'classnames';

import { useSpring, useTrail, useSprings, a, SpringConfig, to } from 'react-spring';
import { useGesture } from 'react-use-gesture';
import { defaultSpringConfig, animationTriggerThreshold } from 'components/Animations/SpringProperties/SpringProperties';

import TriggerLink from 'components/Links/TriggerLink/TriggerLink';

import { useTimeout } from 'utils/Index';

export interface ICursorPosition {
  x: number;
  y: number;
}

export interface IHeroDimensions {
  width: number;
  height: number;
}

export interface IHomeHeroSlide {
  title: string;
  description: string;
  backgroundImageUrl: string;
  logomarkBackgroundImageUrl: string;
  logomarkOutlineImageUrl: string;
  logomarkFrontImageUrl: string;
  logomarkFlatImageUrl: string;
  projectUrl: string;
  projectTitleColor: string;
  ctaBackground: string;
}

interface IProps {
  wrapperRef: MutableRefObject<HTMLDivElement>;
  slideNumber: number;
  slideId: string;
  animateIn: boolean;
  animateInBackground: boolean;
  animateOut: boolean;
  project: IHomeHeroSlide;
  changeMainTitleState(hide: boolean): any;
  stopAutomaticSlider(): any;
  startAutomaticSlider(): any;
}

const HomeHeroSlideComponent: React.FC<IProps> = ({ wrapperRef, animateIn, animateOut, animateInBackground, slideNumber, project, ...props }) => {
  const viewportWidth = useSelector(fromViewport.getWidth);

  const [componentMounted, setComponentMounted] = useState(false);

  const [mountDelay, setMountDelay] = useState(5000);
  const [mountSlider, setMountSlider] = useState(false);
  useTimeout(() => {
    setComponentMounted(true);
    setMountSlider(false);
  }, mountSlider ? mountDelay : null);
  const [heroWidth, setHeroWidth] = useState(null);

  useEffect(() => {
    const updateHeroWidth = () => {
      const wrapperElement = wrapperRef.current;
      setHeroWidth(wrapperElement.getBoundingClientRect().width);
    };

    updateHeroWidth();

    // Setup event listeners on initial mount to keep the  dimensions of the slider updated
    window.addEventListener("resize", updateHeroWidth);

    return () => {
      window.removeEventListener("resize", updateHeroWidth);
    };
  }, []);

  useLayoutEffect(() => {
    if (!componentMounted) {
      if (slideNumber === 0)
        setMountDelay(0);

      setMountSlider(true);
    }
  }, [componentMounted, slideNumber]);

  const heroBackgroundImage = '/assets/images/work/' + project.backgroundImageUrl;
  const logomarkBackgroundImage = '/assets/images/work/' + project.logomarkBackgroundImageUrl;
  const logomarkOutlineImage = '/assets/images/work/' + project.logomarkOutlineImageUrl;
  const logomarkFrontImage = '/assets/images/work/' + project.logomarkFrontImageUrl;
  const logomarkFlatImage = '/assets/images/work/' + project.logomarkFlatImageUrl;

  const animateInSpringConfig: SpringConfig = {
    ...defaultSpringConfig,
    tension: 135,
  };
  const animateOpacityInSpringConfig: SpringConfig = {
    ...animateInSpringConfig,
    tension: 170,
  };
  // const initialTrailInTarget = { x: 0, immediate: false, config: animateInSpringConfig };
  // const [logomarkInSpring, setLogomarkInSpring] = useTrail(4, () => (initialTrailInTarget));
  const [logomarkInSpring, setLogomarkInSpring] = useSprings(4, i => ({
    x: 0,
    immediate: false,
    config: animateInSpringConfig,
    default: true,
    onChange: () => {
      setLogomarkInSpring(j => {
        if (j !== 0) {
          return ({
            x: logomarkInSpring[j - 1].x.get(),
            config: animateInSpringConfig,
          });
        }
      });
    },
  }));
  // const [featuredPosInSpring, setFeaturedPosInSpring] = useTrail(5, () => (initialTrailInTarget));
  const [featuredPosInSpring, setFeaturedPosInSpring] = useSprings(5, i => ({
    x: 0,
    immediate: false,
    config: animateInSpringConfig,
    default: true,
    onChange: () => {
      setFeaturedPosInSpring(j => {
        if (j !== 0) {
          return ({
            x: featuredPosInSpring[j - 1].x.get(),
            config: animateInSpringConfig,
          });
        }
      });
    },
  }));
  // const [featuredOpacityInSpring, setFeaturedOpacityInSpring] = useTrail(5, () => ({ ...initialTrailInTarget, config: animateOpacityInSpringConfig }));
  const [featuredOpacityInSpring, setFeaturedOpacityInSpring] = useSprings(5, i => ({
    x: 0,
    immediate: false,
    config: animateOpacityInSpringConfig,
    default: true,
    onChange: () => {
      setFeaturedOpacityInSpring(j => {
        if (j !== 0) {
          return ({
            x: featuredOpacityInSpring[j - 1].x.get(),
            config: animateOpacityInSpringConfig,
          });
        }
      });
    },
  }));

  const animateLogomarkOpacityInSpringConfig: SpringConfig = {
    ...animateInSpringConfig,
    tension: 170,
  };
  const [logomarkOpacityInSpring, setLogomarkOpacityInSpring] = useSpring(() => ({ x: 0, immediate: false, config: animateLogomarkOpacityInSpringConfig }));
  useEffect(() => {
    const target = animateIn ? { x: 1, immediate: false } : { x: 0, immediate: true };
    setLogomarkInSpring(i => i === 0 ? target : null);
    setFeaturedPosInSpring(i => i === 0 ? target : null);
    setFeaturedOpacityInSpring(i => i === 0 ? target : null);
    setLogomarkOpacityInSpring(target);
  }, [animateIn]);

  const animateBackgroundOpacitySpringConfig: SpringConfig = {
    ...animateInSpringConfig,
    tension: 170,
  };
  const [backgroundOpacitySpring, setBackgroundOpacitySpring] = useSpring(() => ({ x: 0, immediate: false, config: animateBackgroundOpacitySpringConfig }));
  useEffect(() => {
    setBackgroundOpacitySpring(animateInBackground || animateOut ? { x: 1, immediate: false } : { x: 0, immediate: true });
  }, [animateInBackground, animateOut]);

  const animateOutSpringConfig: SpringConfig = {
    ...animateInSpringConfig,
    tension: 170,
  };
  const animateOpacityOutSpringConfig: SpringConfig = {
    ...animateOutSpringConfig,
    tension: 240,
  };
  // const initialOutTarget = { x: 0, immediate: false, config: animateOutSpringConfig };
  // const [logomarkOutSpring, setLogomarkOutSpring] = useTrail(3, () => (initialOutTarget));
  const [logomarkOutSpring, setLogomarkOutSpring] = useSprings(3, i => ({
    x: 0,
    immediate: false,
    config: animateOutSpringConfig,
    default: true,
    onChange: () => {
      setLogomarkOutSpring(j => {
        if (j !== 0) {
          return ({
            x: logomarkOutSpring[j - 1].x.get(),
            config: animateOutSpringConfig,
          });
        }
      });
    },
  }));
  // const [featuredPosOutSpring, setFeaturedPosOutSpring] = useTrail(3, () => (initialOutTarget));
  const [featuredPosOutSpring, setFeaturedPosOutSpring] = useSprings(3, i => ({
    x: 0,
    immediate: false,
    config: animateOutSpringConfig,
    default: true,
    onChange: () => {
      setFeaturedPosOutSpring(j => {
        if (j !== 0) {
          return ({
            x: featuredPosOutSpring[j - 1].x.get(),
            config: animateOutSpringConfig,
          });
        }
      });
    },
  }));
  // const [featuredOpacityOutSpring, setFeaturedOpacityOutSpring] = useTrail(3, () => ({ ...initialOutTarget, config: animateOpacityOutSpringConfig }));
  const [featuredOpacityOutSpring, setFeaturedOpacityOutSpring] = useSprings(3, i => ({
    x: 0,
    immediate: false,
    config: animateOpacityOutSpringConfig,
    default: true,
    onChange: () => {
      setFeaturedOpacityOutSpring(j => {
        if (j !== 0) {
          return ({
            x: featuredOpacityOutSpring[j - 1].x.get(),
            config: animateOpacityOutSpringConfig,
          });
        }
      });
    },
  }));

  const animateLogomarkOpacityOutSpringConfig: SpringConfig = {
    ...animateInSpringConfig,
    tension: 205,
  };
  const [logomarkOpacityOutSpring, setLogomarkOpacityOutSpring] = useSpring(() => ({ x: 0, immediate: false, config: animateLogomarkOpacityOutSpringConfig }));
  useEffect(() => {
    const target = animateOut ? { x: 1, immediate: false } : { x: 0, immediate: true };
    setLogomarkOutSpring(i => i === 0 ? target : null);
    setFeaturedPosOutSpring(i => i === 0 ? target : null);
    setFeaturedOpacityOutSpring(i => i === 0 ? target : null);
    setLogomarkOpacityOutSpring(target);
  }, [animateOut]);

  const animateCursorXSpringConfig: SpringConfig = {
    ...animateInSpringConfig,
    tension: 170,
  };
  const [xPosition, setXPosition] = useSpring(() => ({ x: 0, config: animateCursorXSpringConfig }));
  const bind = useGesture({
    onMove: state => handleMove(state),     // fires on mouse move over the element
  }, { domTarget: wrapperRef });
  useEffect(bind, [bind]);

  const handleMove = state => {
    const { xy: [x] } = state;
    setXPosition({ x });
  };

  const logomarkRange = 20;
  const flatImageRange = 15;
  const backgroundImageRange = 5;
  const outlineImageRange = 10;
  const frontImageRange = 30;

  const backgroundStyles = {
    opacity: backgroundOpacitySpring.x,
  };

  const logomarkWrapperStyles = {
    opacity: to(
      [logomarkOpacityInSpring.x, logomarkOpacityOutSpring.x],
      (inO, outO) => inO - outO,
    ),
    transform: to(
      [logomarkInSpring[0].x, logomarkOutSpring[0].x],
      (inX, outX) => `translateX( calc(     ( ( ${inX} * -${logomarkRange}px ) + ${logomarkRange}px ) + ( ${outX} * -${logomarkRange}px )     ) )`,
    ),
    right: `50vw`,
    willChange: animateIn ? `opacity` : ``,
    //willChange: animateIn ? `opacity, transform` : ``,
  };

  const flatImageStyles = {
    transform: xPosition.x.to(cursorX => `translateX( calc(     ( ( ${cursorX} - ( ${heroWidth} / 2 ) ) / ${heroWidth} ) * -${flatImageRange}px     ) )`),
    //willChange: animateIn ? `transform` : ``,
  };

  const backgroundImageStyles = {
    transform: to(
      [logomarkInSpring[2].x, logomarkOutSpring[2].x, xPosition.x], (inX, outX, cursorX) => `translateX( calc(     ( ( ( ${cursorX} - ( ${heroWidth} / 2 ) ) / ${heroWidth} ) * -${backgroundImageRange}px ) + ${backgroundImageRange}px + ( ( ${inX} * -${backgroundImageRange}px ) + ${backgroundImageRange}px ) + ( ${outX} * -${backgroundImageRange}px )     ) )`,
    ),
    //willChange: animateIn ? `transform` : ``,
  };

  const outlineImageStyles = {
    transform: to(
      [logomarkInSpring[1].x, logomarkOutSpring[1].x, xPosition.x],
      (inX, outX, cursorX) => `translateX( calc(     ( ( ( ${cursorX} - ( ${heroWidth} / 2 ) ) / ${heroWidth} ) * -${outlineImageRange}px ) + ${outlineImageRange}px + ( ( ${inX} * -${outlineImageRange}px ) + ${outlineImageRange}px ) + ( ${outX} * -${outlineImageRange}px )     ) )`,
    ),
    //willChange: animateIn ? `transform` : ``,
  };

  const frontImageStyles = {
    transform: to(
      [logomarkInSpring[0].x, logomarkOutSpring[0].x, xPosition.x],
      (inX, outX, cursorX) => `translateX( calc(     ( ( ( ${cursorX} - ( ${heroWidth} / 2 ) ) / ${heroWidth} ) * -${frontImageRange}px ) + ( ( ${inX} * -${frontImageRange}px ) + ${frontImageRange}px ) + ( ${outX} * -${frontImageRange}px )     ) )`,
    ),
    //willChange: animateIn ? `transform` : ``,
  };

  const logomarkTriggerStyles = {
    opacity: to(
      [logomarkOpacityInSpring.x, logomarkOpacityOutSpring.x],
      (inO, outO) => inO - outO,
    ),
    transform: to([logomarkInSpring[0].x, logomarkOutSpring[0].x], (inX, outX) => `translateX( calc(     ( ( ${inX} * -${logomarkRange}px ) + ${logomarkRange}px ) + ( ${outX} * -${logomarkRange}px )     ) )`),
    right: `50%`,
    willChange: animateIn ? `opacity, transform` : ``,
  };

  const sideLineStyles = {
    opacity: to(
      [featuredOpacityInSpring[3].x, featuredOpacityOutSpring[1].x],
      (inO, outO) => (animateOut ? (1 - (outO)) : (animateIn ? inO : 0)) * 0.8,
    ),
    transform: featuredPosInSpring[3].x.to(inX => `translateX( calc(     ( ( ${inX} * -40px ) + 40px )     ) )`),
    backgroundColor: project.projectTitleColor,
    willChange: animateIn ? `opacity, transform` : ``,
  };

  const featuredTitleStyles = {
    opacity: to(
      [featuredOpacityInSpring[2].x, featuredOpacityOutSpring[2].x],
      (inO, outO) => (animateOut ? (1 - (outO)) : (animateIn ? inO : 0)) * 0.6,
    ),
    transform: featuredPosInSpring[2].x.to(inX => `translateX( calc(     ( ( ${inX} * -20px ) + 20px )     ) )`),
    color: project.projectTitleColor,
    willChange: animateIn ? `opacity, transform` : ``,
  };

  const projectTitleStyles = {
    opacity: to(
      [featuredOpacityInSpring[3].x, featuredOpacityOutSpring[1].x],
      (inO, outO) => (animateOut ? (1 - (outO)) : (animateIn ? inO : 0)) * 0.8,
    ),
    transform: featuredPosInSpring[3].x.to(inX => `translateX( calc(     ( ( ${inX} * -20px ) + 20px )     ) )`),
    color: project.projectTitleColor,
    willChange: animateIn ? `opacity, transform` : ``,
  };

  const projectDescriptionStyles = {
    opacity: to(
      [featuredOpacityInSpring[4].x, featuredOpacityOutSpring[0].x],
      (inO, outO) => (animateOut ? (1 - (outO)) : (animateIn ? inO : 0)) * 0.5,
    ),
    transform: featuredPosInSpring[4].x.to(inX => `translateX( calc(     ( ( ${inX} * -20px ) + 20px )     ) )`),
    color: project.projectTitleColor,
    willChange: animateIn ? `opacity, transform` : ``,
  };

  return (
    <div
      className={classNames(
        'slideWrapperPosition',
        { hideOffscreen: (slideNumber !== 0) && !componentMounted },
      )}
    >
      <a.div
        className={classNames(
          'featuredWrapper',
          { active: animateIn },
        )}
      >
        <a.div
          className={classNames(
            'background',
            { active: animateInBackground },
          )}
          style={backgroundStyles}
        >
          <LazyImage
            width='100%'
            height='100%'
            src={heroBackgroundImage}
            alt={project.title}
            fit='cover'
            animateImageIn={true}
            animationType={'fadeIn'}
          />
        </a.div>
        <a.div className='logomarkWrapper' style={logomarkWrapperStyles}>
          <div className='logomark shadow'>
            <div className='shadowImage' />
            <BlockLink to={'/work/' + project.projectUrl} title={project.title}>
              <a.div className='flatImage' style={flatImageStyles}>
                <LazyImage
                  width='100%'
                  height='100%'
                  src={logomarkFlatImage}
                  alt={project.title}
                  fit='contain'
                  animateImageIn={true}
                  animationType={'fadeIn'}
                />
              </a.div>
            </BlockLink>
          </div>
          <div className='logomark outline'>
            <a.div className='backgroundImage' style={backgroundImageStyles}>
              <LazyImage
                width='100%'
                height='100%'
                src={logomarkBackgroundImage}
                alt={project.title}
                fit='contain'
                animateImageIn={true}
                animationType={'fadeIn'}
              />
            </a.div>
          </div>
          <div className='logomark outline'>
            <a.div className='backgroundImage' style={outlineImageStyles}>
              <LazyImage
                width='100%'
                height='100%'
                src={logomarkOutlineImage}
                alt={project.title}
                fit='contain'
                animateImageIn={true}
                animationType={'fadeIn'}
              />
            </a.div>
          </div>
          <div className='logomark extension'>
            <BlockLink to={'/work/' + project.projectUrl} title={project.title}>
              <a.div className='frontImage' style={frontImageStyles}>
                <LazyImage
                  width='100%'
                  height='100%'
                  src={logomarkFrontImage}
                  alt={project.title}
                  fit='contain'
                  animateImageIn={true}
                  animationType={'fadeIn'}
                />
              </a.div>
            </BlockLink>
          </div>
        </a.div>
        <a.div className='logomarkWrapper trigger' style={logomarkTriggerStyles}>
          <div className='logomark trigger'>
            <div className='caseStudyTriggerContainer'>
              <TriggerLink
                to={'/work/' + project.projectUrl}
                backgroundShade={'light'}
                animationReady={animateIn}
                animateIn={logomarkInSpring[3].x.to(x => x > animationTriggerThreshold)}
                styles={{}}
                title={project.title}
              >
                Check out the case study
              </TriggerLink>
            </div>
          </div>
        </a.div>
        <div className='featuredProjectWrapper'>
          <a.div className='sideLine' style={sideLineStyles} />
          <div className='contentWrapper'>
            <BlockLink
              to={'/work/' + project.projectUrl}
              title={project.title}
            >
              <a.h2 style={featuredTitleStyles}>
                Featured Project
              </a.h2>
              <a.h1 style={projectTitleStyles}>
                {project.title}
              </a.h1>
              <a.p style={projectDescriptionStyles}>
                {project.description}
              </a.p>
            </BlockLink>
          </div>
        </div>
      </a.div>
    </div>
  );
};

// Wire up the React component to the Redux store
export const HomeHeroSlide = HomeHeroSlideComponent;










