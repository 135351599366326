import React, { useState } from 'react';

import { usePageWrapper } from 'hooks/usePageWrapper';
import { SEOHelmet } from 'components/SEO/SEOHelmet';

import HomeHero from 'components/Heros/HomeHero/HomeHero';
import StudioBlurb from 'components/StudioBlurb/StudioBlurb';
import { FullSection } from 'components/Layout/FullSection/FullSection';
import { FullSectionContent } from 'components/Layout/FullSectionContent/FullSectionContent';
import { IHomeServices, HomeService } from 'components/ServiceSections/HomeService/HomeService';

import FooterCta from 'components/Footer/FooterCta/FooterCta';

const Home: React.FC<{}> = () => {
  const [PageWrapperComponent, animatePageContentIn] = usePageWrapper();
  //const { animatePageContentIn } = usePageWrapper();

  const [servicesData] = useState<IHomeServices>({
    podiumValuations: {
      title: 'We help craft complete experiences that disrupt existing markets',
      description: 'We helped Podium Valuations in building out their business idea into a fully fledged platform to allow people to get valuations from official auction houses from the comfort of their own homes.',
      linkUrl: '/work/podiumValuations',
      caseStudy: true,
      projectLogoUrl: 'podiumValuations/serviceLogoLight.png',
      projectName: 'Podium Valuations',
      projectImage: {
        url: 'podiumValuations/serviceImage.png',
        alt: 'The final interface prototype for Podium Valuations',
      },
      projectImagePosition: 0,
    },
    bite: {
      title: 'We design and create well thought out and beautiful websites and apps',
      description: 'We designed and developed the Bite brand, Android app, and website to give students on university campuses ephemereal food offers in real time. This allowed food outlets to push time constrained offers to the student population instantly.',
      linkUrl: '/work/bite',
      caseStudy: false,
      projectLogoUrl: 'bite/serviceLogo.png',
      projectName: 'Bite',
      projectImage: {
        url: 'bite/serviceImage.png',
        alt: 'The entire Bit app and website ecosystem',
      },
      projectImagePosition: -90,
    },
    qit: {
      title: 'We elevate businesses and tell their stories through their brands',
      description: 'We helped QIT Solutions evolve their brand and website, from a slightly dated look, and distilled it into it’s most important components. It went beyond their image and extended throughout their entire digital presence.',
      linkUrl: '/work/qit',
      caseStudy: false,
      projectLogoUrl: 'qit/serviceLogo.png',
      projectName: 'QIT Solutions',
      projectImage: {
        url: 'qit/serviceImage.png',
        alt: 'The brand created for QIT Solutions',
      },
      projectImagePosition: 60,
    },
  });

  return PageWrapperComponent(
      <div className='homePage'>
        <SEOHelmet
          schema="WebPage"
          title="We create extraordinary experiences to help tell your brand's story"
          description="We create extraordinary experiences to help tell your brand's story"
          contentType="product"
        />
        <HomeHero animationReady={animatePageContentIn} />
        <StudioBlurb animationReady={animatePageContentIn} />
        <FullSection animationReady={animatePageContentIn} noTopPadding>
          <FullSectionContent>
            {Object.keys(servicesData).map((service, index) => {
              return <HomeService
                animationReady={animatePageContentIn}
                contentAlignRight={((index % 2 !== 0) ? true : false)}
                firstOne={index === 0}
                service={servicesData[service]}
                key={index}
              />;
            })}
          </FullSectionContent>
        </FullSection>
        <FooterCta animationReady={animatePageContentIn} />
      </div>,
  );
};

export default Home;

