import React from 'react';

import { a } from 'react-spring';

import { useIntersect } from 'hooks/useIntersect';
import { useAnimateInTrail, IAnimateInProps } from 'hooks/useAnimateInTrail';
import { initialOffset } from 'components/Animations/SpringProperties/SpringProperties';
import { InterfaceWrapper } from 'components/Layout/InterfaceWrapper/InterfaceWrapper';

import classNames from 'classnames';

interface IOwnSpecificProps {
  title?: JSX.Element;
  description?: JSX.Element;
  scheme?: string;
  noTopPadding?: boolean;
  extraTopPadding?: boolean;
  extraBottomPadding?: boolean;
  longerDescription?: boolean;
}

type IOwnProps = IOwnSpecificProps & IAnimateInProps;

type IProps = IOwnProps;

const FullSectionComponent: React.FC<IProps> = ({
  animationReady,
  title = null,
  description = null,
  scheme = null,
  noTopPadding = false,
  extraTopPadding = false,
  extraBottomPadding = false,
  longerDescription = false,
  ...props
}) => {
  const [inViewRef, inView] = useIntersect({
    startObserving: animationReady,
    rootMargin: "-150px 0px -150px 0px",
  });

  const animateInTrail = useAnimateInTrail(inView, 2);

  const titleStyles = {
    opacity: animateInTrail[0].active,
    transform: animateInTrail[0].active.to(x => `translateY(calc(${initialOffset(x)}))`),
  };

  const descriptionStyles = {
    opacity: animateInTrail[1].active,
    transform: animateInTrail[1].active.to(x => `translateY(calc(${initialOffset(x)}))`),
  };

  return (
    <InterfaceWrapper animationReady={animationReady}>
      <section
        ref={inViewRef}
        className={classNames(
          'fullSectionWrapper',
          { ['section-scheme-' + scheme]: (scheme != null) },
          { noTopPadding },
          { extraTopPadding },
          { extraBottomPadding },
          { longerDescription },
        )}
      >
        {(title != null ?
          <a.h1 style={titleStyles}>{title}</a.h1>
          :
          null
        )}
        {(description != null ?
          <a.div className='description' style={descriptionStyles}>
            <div>{description}</div>
          </a.div>
          :
          null
        )}
        {props.children}
      </section>
    </InterfaceWrapper>
  );
};

export const FullSection = FullSectionComponent;
