import React, { useState, MutableRefObject, useEffect } from 'react';
import { useInterval, useTimeout } from 'utils/Index';

import { ICursorPosition, IHeroDimensions, IHomeHeroSlide, HomeHeroSlide } from 'components/Heros/HomeHero/HomeHeroSlide';

export interface IHomeHeroSlides {
  [name: string]: IHomeHeroSlide;
}

interface IOwnSpecificProps {
  wrapperRef: MutableRefObject<HTMLDivElement>;
  animateIn?: boolean;
  changeMainTitleState(hide: boolean): any;
  changeCtaBackgroundColor(backgroundColor: string): any;
}

type IOwnProps = IOwnSpecificProps;

type IProps = IOwnProps;

const HomeHeroSliderComponent: React.FC<IProps> = ({ wrapperRef, animateIn, ...props }) => {
  const [slidesData] = useState<IHomeHeroSlides>({
    citiesOfTheFuture: {
      title: 'Cities of the Future',
      description: 'A new type of city that was pitched to the King of Dubai',
      backgroundImageUrl: 'citiesOfTheFuture/homeHeroBackground.jpg',
      logomarkBackgroundImageUrl: 'citiesOfTheFuture/logomarkBackgroundImage.jpg',
      logomarkOutlineImageUrl: 'citiesOfTheFuture/logomarkOutlineImage.png',
      logomarkFrontImageUrl: 'citiesOfTheFuture/logomarkFrontImage.png',
      logomarkFlatImageUrl: 'citiesOfTheFuture/logomarkFlatImage.png',
      projectUrl: 'citiesOfTheFuture',
      projectTitleColor: '#a7c3df',
      ctaBackground: '#051e37',
    },
    jlr: {
      title: 'JLR Pakistan Proposal',
      description: 'A refined and high end brochure for a group of talented individuals',
      backgroundImageUrl: 'jlr/homeHeroBackground.jpg',
      logomarkBackgroundImageUrl: 'jlr/logomarkBackgroundImage.jpg',
      logomarkOutlineImageUrl: 'jlr/logomarkOutlineImage.png',
      logomarkFrontImageUrl: 'jlr/logomarkFrontImage.png',
      logomarkFlatImageUrl: 'jlr/logomarkFlatImage.png',
      projectUrl: 'jlr',
      projectTitleColor: '#f6ddc4',
      ctaBackground: '#1e1e1e',
    },
  });
  const [initialised, setInitialised] = useState(false);
  const [slideIdIn, setSlideIdIn] = useState(-1);
  const [slideIdBackgroundIn, setSlideIdBackgroundIn] = useState(-1);
  const [slideIdOut, setSlideIdOut] = useState(Object.keys(slidesData).length);
  const [slideTotal, setSlideTotal] = useState(Object.keys(slidesData).length);

  const [automaticSwitch, setAutomaticSwitch] = useState(false);

  const [startSliderTimeout, setStartSliderTimeout] = useState(false);
  useTimeout(() => {
    startAutomaticSlider();
    setStartSliderTimeout(false);
  }, startSliderTimeout ? 500 : null);

  useInterval(() => {
    nextSlide();
  }, automaticSwitch ? 7000 : null);

  useEffect(() => {
    let currentSlide = slideIdIn;
    if (animateIn && currentSlide === -1 && !initialised) {
      setInitialised(true);
      currentSlide = 0;
      setStartSliderTimeout(true);
      props.changeCtaBackgroundColor(slidesData[Object.keys(slidesData)[currentSlide]].ctaBackground);
    }
    setSlideIdIn(currentSlide);
    setSlideIdBackgroundIn((slideIdBackgroundIn === -1 ? currentSlide : slideIdBackgroundIn));
  });

  const startAutomaticSlider = () => setAutomaticSwitch(true);

  const stopAutomaticSlider = () => setAutomaticSwitch(false);

  const [resetSlideTimeout, setResetSlideTimeout] = useState(false);
  useTimeout(() => {
    setSlideIdOut(-1);
    setResetSlideTimeout(false);
  }, resetSlideTimeout ? 500 : null);

  const [actuallySwitchSlide, setActuallySwitchSlide] = useState(false);
  useTimeout(() => {
    setSlideIdIn(slideIdBackgroundIn);
    setResetSlideTimeout(true);
    setActuallySwitchSlide(false);
  }, actuallySwitchSlide ? 900 : null);

  const nextSlide = () => {
    if (automaticSwitch) {
      const currentSlideId = slideIdIn;
      let nextSlideId = currentSlideId + 1;
      if (nextSlideId === slideTotal)
        nextSlideId = 0;

      setSlideIdOut(currentSlideId);
      setSlideIdBackgroundIn(nextSlideId);
      props.changeCtaBackgroundColor(slidesData[Object.keys(slidesData)[nextSlideId]].ctaBackground);

      setActuallySwitchSlide(true);
    }
  };

  return (
    <div>
      {Object.keys(slidesData).map((slide, index) => (
        <HomeHeroSlide
          wrapperRef={wrapperRef}
          key={index}
          slideNumber={index}
          slideId={slide}
          animateIn={slideIdIn === index}
          animateInBackground={slideIdBackgroundIn === index}
          animateOut={slideIdOut === index}
          project={slidesData[slide]}
          changeMainTitleState={props.changeMainTitleState}
          stopAutomaticSlider={stopAutomaticSlider}
          startAutomaticSlider={startAutomaticSlider}
        />
      ))}
    </div>
  );
};

export const HomeHeroSlider = HomeHeroSliderComponent;
