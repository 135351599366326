import React, { useState, useRef, useEffect } from 'react';

import classNames from 'classnames';

import { a, useSpring, SpringConfig } from 'react-spring';

import { useIntersect } from 'hooks/useIntersect';
import { useAnimateInTrail, IAnimateInProps } from 'hooks/useAnimateInTrail';
import { initialOffset, defaultSpringConfig, animationTriggerThreshold } from 'components/Animations/SpringProperties/SpringProperties';
import { InterfaceWrapper } from 'components/Layout/InterfaceWrapper/InterfaceWrapper';

import { ButtonLink } from 'components/Links/Index';

import { HomeHeroSlider } from 'components/Heros/HomeHero/HomeHeroSlider';
//import ScrollLine from 'components/Heros/ScrollLine/ScrollLine';

import { useTimeout } from 'utils/Index';

type IOwnProps = IAnimateInProps;

type IProps = IOwnProps;

const HomeHero: React.FC<IProps> = ({ animationReady, ...props }) => {
  const [animateLogomarkIn, setAnimateLogomarkIn] = useState(false);
  const [animateScrollIn, setAnimateScrollIn] = useState(false);
  const [titleVisible, setTitleVisible] = useState(true);
  const [ctaBackgroundColor, setCtaBackgroundColor] = useState('#1b1b1b');

  const wrapper = useRef<HTMLDivElement>(null);

  const [inViewRef, inView] = useIntersect({
    startObserving: animationReady,
  });

  const animateInTrail = useAnimateInTrail(inView, 4);

  const titleStyles = {
    opacity: animateInTrail[0].active,
    transform: animateInTrail[0].active.to(x => `translateY(calc(${initialOffset(x)}))`),
  };

  const descriptionStyles = {
    opacity: animateInTrail[1].active,
    transform: animateInTrail[1].active.to(x => `translateY(calc(${initialOffset(x)}))`),
  };

  const ctaStyles = {
    opacity: animateInTrail[2].active,
    transform: animateInTrail[2].active.to(x => `translateY(calc(${initialOffset(x)}))`),
  };

  const [logomarkTimeoutDelay] = useState(200);
  const [logomarkTimeout, setLogomarkTimeout] = useState(false);
  const [scrollTimeoutDelay] = useState(200);
  const [scrollTimeout, setScrollTimeout] = useState(false);

  useTimeout(() => {
    setAnimateLogomarkIn(true);
    setScrollTimeout(true);
  }, logomarkTimeout ? logomarkTimeoutDelay : null);

  useTimeout(() => {
    setAnimateScrollIn(true);
  }, scrollTimeout ? scrollTimeoutDelay : null);

  useEffect(() => {
    if (inView && animationReady && !animateLogomarkIn && !animateScrollIn)
      setLogomarkTimeout(true);
  }, [inView, animationReady, animateLogomarkIn, animateScrollIn]);

  const changeMainTitleState = (hide: boolean) => {
    if (hide)
      setTitleVisible(false);
    else
      setTitleVisible(true);
  };

  const changeCtaBackgroundColor = (backgroundColor: string) => {
    setCtaBackgroundColor(backgroundColor);
  };

  const springConfig: SpringConfig = {
    ...defaultSpringConfig,
    tension: 100,
  };

  const [hideMessageWrapper, setHideMessageWrapper] = useState(false);

  const finishedTitleOpacityAnimation = restValues => {
    alert(restValues.x);
    if (restValues.x === 1)
      setHideMessageWrapper(false);
    else
      setHideMessageWrapper(true);
  };

  const [titleOpacitySpring, setTitleOpacitySpring] = useSpring(() => ({
    x: 1,
    config: springConfig,
    onRest: restValues => finishedTitleOpacityAnimation(restValues),
  }));

  useEffect(() => {
    setTitleOpacitySpring({ x: titleVisible ? 1 : 0 });
  }, [titleVisible]);

  const messageWrapperStyles = {
    opacity: titleOpacitySpring.x,
  };

  return (
    <InterfaceWrapper animationReady={animationReady}>
      {/*<svg width="0" height="0">
            <clipPath id="clipPolygon">
                <polygon points="200 250,400 100,300 50,0 0">
                </polygon>
            </clipPath>
        </svg>*/}
      <section className='homeHeroWrapper' ref={wrapper}>
        <div className='componentWrapper' ref={inViewRef}>
          <HomeHeroSlider
            wrapperRef={wrapper}
            animateIn={animateLogomarkIn}
            changeMainTitleState={changeMainTitleState}
            changeCtaBackgroundColor={changeCtaBackgroundColor}
          />
          <div>
            <a.div
              className={classNames(
                'messageWrapper',
                { hidden: hideMessageWrapper },
              )}
              style={messageWrapperStyles}
            >
              <a.h1 style={titleStyles}>
                We create extraordinary experiences to help tell your brand’s story
              </a.h1>
              <a.p style={descriptionStyles}>
                Dedicated to crafting and delivering meaningful brands, products, and experiences that people love.
              </a.p>
              <a.div className='ctaWrapper' style={ctaStyles}>
                <ButtonLink
                  to='/work'
                  type='medium'
                  arrow
                  animationReady={animationReady}
                  animateIn={animateInTrail[2].active.to(x => x > animationTriggerThreshold)}
                  backgroundColor={ctaBackgroundColor}
                  title='Our latest work'
                >
                  Our latest work
                </ButtonLink>
              </a.div>
            </a.div>
            {/*<ScrollLine animateIn={true} />*/}
          </div>
        </div>
      </section>
    </InterfaceWrapper>
  );
};

export default HomeHero;
